/**
 * @ldClient.js
 * Wraps launch darkly client sdk
 *
 * Usage:
 * const featureFlagClient = await ldClient.getClient();
 * const flagValue = featureFlagClient.variation('enable-agency-automation-api', false);
 *
 * Subscribe:
 * featureFlagClient.on(`change:enable-agency-automation-api`, async (value) => {
        flagValue = value
      });
 */

 const LaunchDarkly = require("launchdarkly-js-client-sdk");
 import manifest from "../manifest";

 const {
   clients: {
     launchDarkly: { clientSideId },
   },
 } = manifest;

const ldOptions = {
  application: {
    id: 'zengarden'
  },
  sendEventsOnlyForVariation: true
};


let launchDarklyClient;
async function initialize(user) {
  if (!user) {
    user = {
      key: "anonymous",
    };
  }
  const client = LaunchDarkly.initialize(clientSideId, user, ldOptions);
  await client.waitUntilReady();
  return client;
}

export async function getClient(user) {
  if (launchDarklyClient) return launchDarklyClient;
  return (launchDarklyClient = await initialize(user));
}


