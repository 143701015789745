import { format } from 'date-fns';
export const COUPON_LABELS = {
  ISSUED: 'Issued ',
  REDEEMED: 'Redeemed',
  EXPIRED: 'Expired',
}

export const getCouponStatus = (couponJourney, relatedPromotion, termEndDate) => {

  const DEFAULT_STATUS = {
    buttonStatus: true,
    statusMessage: null,
  };

  if (!couponJourney && !relatedPromotion) return DEFAULT_STATUS;

  const statusMapping = {
    not_started: {
      buttonStatus: false,
      statusMessage: `${COUPON_LABELS.ISSUED} ${format(new Date(couponJourney.createdDatetime), 'MMMM dd, yyyy hh:mm:ss a')}`,
    },
    completed: relatedPromotion ? {
      buttonStatus: false,
      statusMessage: COUPON_LABELS.REDEEMED,
    } : DEFAULT_STATUS,
    expired: new Date() < new Date(termEndDate) ? {
      buttonStatus: true,
      statusMessage: COUPON_LABELS.EXPIRED,
    } : DEFAULT_STATUS,
  };

  return statusMapping[couponJourney?.status] || DEFAULT_STATUS;
}

