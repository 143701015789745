export const tx2024ChangesEnabled = (complianceEvents, orderTask, featureFlagClient) => {
  const isFlagEnabled = featureFlagClient.variation('enable-tx-annual-report-flow-changes', false);
  const { order_task_uuid } = orderTask
  const complianceEvent = complianceEvents?.find((event) => event.order_task_uuid === order_task_uuid);
  return isFlagEnabled && !!complianceEvent && new Date(complianceEvent.event_date) >= new Date('2024-01-01');
}

export const minJourneyEnabled = (featureFlagClient) => {
  const isFlagEnabled = featureFlagClient.variation('enable-mini-journey-button-access', false);
  return isFlagEnabled;
}

export const couponJourneyEnabled = (featureFlagClient) => {
  const isFlagEnabled = featureFlagClient.variation('enable-coupon-journey-button-access', false);
  return isFlagEnabled;
}

export const couponJourneyStatusEnabled = (featureFlagClient) => {
  const isFlagEnabled = featureFlagClient.variation('enable-coupon-journey-status-access', false);
  return isFlagEnabled;
}

