import { render, staticRenderFns } from "./collapsible-panel.vue?vue&type=template&id=61e07291&scoped=true"
import script from "./collapsible-panel.vue?vue&type=script&lang=js"
export * from "./collapsible-panel.vue?vue&type=script&lang=js"
import style0 from "./collapsible-panel.vue?vue&type=style&index=0&id=61e07291&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61e07291",
  null
  
)

export default component.exports