<template>
  <Modal @close="$emit('closeRaModal')" :showOverflow="true">
    <template v-slot:title>
      <transition name="fade" mode="out-in">
        <span key="initial" v-if="isRaUpdateSuccess">Are you sure?</span>
        <span key="failure" v-else>Uh Oh!</span>
      </transition>
    </template>
    <template v-slot:body>
      <transition name="fade" mode="out-in">
        <div key="initial" v-if="isRaUpdateSuccess">
          <p>Update the <strong>{{ modalDataParam.jurisdiction.jurisdiction_code }}</strong> <em>Registered Agent Service</em> to ZenBusiness.</p>
          <div class="modal__grp">
            <label for="raEffectiveDate" class="modal__item">Effective Date:</label>
            <div class="modal__item">
              <Datepicker
                name="raEffectiveDate"
                calendar-button-icon="fa fa-calendar"
                format="MMMM dd, yyyy"
                :calendar-button="true"
                :typeable="true"
                v-model="raServiceDate" />
            </div>
          </div>
        </div>
        <div key="failure" v-else>
          <p>There was an issue completing this update. Please submit a request to fix this via the “Report an Issue” form in Slack’s application-prod channel</p>
        </div>
      </transition>
    </template>
    <template v-slot:footer>
      <transition name="fade" mode="out-in">
        <span key="initial" v-if="isRaUpdateSuccess">
          <button @click="$emit('closeRaModal')" class="btn btn-danger action" :disabled="isRaUpdateSubmitting">Cancel</button>
          <button @click="updateRaService" class="btn btn-primary action" :disabled="isRaUpdateSubmitting">
            Confirm
            <transition name="btn-thinking" appear v-if="isRaUpdateSubmitting">
              <span class="btn__icon" key="icon"><IconSpinner /></span>
            </transition>
          </button>
        </span>
        <div key="failure" v-else>
          <button @click="$emit('closeRaModal')" class="btn btn-danger action">I understand</button>
        </div>
      </transition>
    </template>
  </Modal>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import IconSpinner from '@/components/common/images/IconSpinner';
import Modal from '@/components/common/Modal';

export default {
  name: 'UpdateRaToZBModal',
  props: ['isRaUpdateSubmitting', 'isRaUpdateSuccess', 'modalData'],
  components: {
    Datepicker,
    IconSpinner,
    Modal
  },
  data () {
    return {
      raServiceDate: new Date(),
      modalDataParam: this.modalData
    }
  },
  methods: {
    updateRaService () {
      this.modalDataParam.raEffectiveDatetime = this.raServiceDate
      this.$emit('updateRAService', this.modalDataParam)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  &__icon {
    display: inline-block;
    width: 1.5em;
  }
  &-thinking{
    &-enter-active, &-leave-active {
      transition: opacity 300ms ease, width 250ms $easeOutQuad;
    }
    &-enter, &-leave-to {
      opacity: 0;
      width: 0em;
    }
  }
}

.fade {
  &-enter-active, &-leave-active {
    transition: opacity 450ms ease;
  }
  &-enter, &-leave-to {
    opacity: 0;
  }
}

.modal {
  &__grp {
    align-items: center;
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
  }
  &__item {
    margin-left: 10px;
    margin-right: 10px;
  }
  &-footer {
    .btn {
      margin: 0 0.25em;
    }
  }
  p {
    float: none; /* bootstrap override */
    padding-left: 0;
    padding-right: 0;
  }
  label {
    font-size: rem(14);
    font-weight: 700;
    margin-bottom: 0;
  }
}
</style>
