<template>
  <div class="modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content" :style="true ? 'overflow: visible;' : ''">
        <div class="modal-header">
          <h5 class="modal-title">
            <span key="initial" v-if="isJourneyUpdateSuccess" >Are you sure you would like to issue this coupon?</span>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('closeJourneyModal')"></button>
        </div>
        <div class="modal-footer">
          <span key="initial" v-if="isJourneyUpdateSuccess">
           <button @click="$emit('closeJourneyModal')" class="btn btn-outline-secondary action" :disabled="isJourneyUpdateSubmitting">Cancel</button>
           <button @click="updateJourneyService" class="btn btn-warning action" :disabled="isJourneyUpdateSubmitting">
            Issue Coupon
            <transition name="btn-thinking" appear v-if="isJourneyUpdateSubmitting">
              <span class="btn__icon" key="icon"><IconSpinner /></span>
            </transition>
          </button>
        </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import IconSpinner from '@/components/common/images/IconSpinner';

export default {
  name: 'CouponJourneyModal',

  props: ['handleCreateCouponJourney', 'modalData', 'isJourneyUpdateSuccess','isJourneyUpdateSubmitting'],
  components: {
    IconSpinner
  },

  methods: {
    updateJourneyService() {
      this.$emit('handleCreateCouponJourney')
    }
  }
};
</script>

<style lang="scss" scoped>

body {
  overflow: hidden;
}
.modal {
  &-dialog {
    &--full-width {
      @include mq($from: xs) {
        max-width: 96vw !important;
      }
    }
  }
  &-body {
    overflow-y: auto;
    max-height: 70vh;
  }
  &-header, &-footer {
    background: rgba($stone-5, .25);
  }
}

.modal {
  &-footer {
    span {
      display: flex;
      justify-content: space-between;
      .btn {
        margin: 0 0.25em;
      }
    }
  }
}
</style>
