import { render, staticRenderFns } from "./CouponJourneyModal.vue?vue&type=template&id=15fb32f4&scoped=true"
import script from "./CouponJourneyModal.vue?vue&type=script&lang=js"
export * from "./CouponJourneyModal.vue?vue&type=script&lang=js"
import style0 from "./CouponJourneyModal.vue?vue&type=style&index=0&id=15fb32f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15fb32f4",
  null
  
)

export default component.exports