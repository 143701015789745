<template>
  <transition name="fade" appear>
    <div class="loading" aria-label="loading" v-if="isLoading">
      <p 
        class="loading__txt" 
        :class="'loading__txt--' + size">
        <span class="loading__ellip">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
        <span class="loading__letters">Loading</span>
      </p>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'LoadingWrapper',
  props: {
    isLoading: Boolean,
    size: {
      type: String,
      default: 'md'
    }
  },

  data () {
    return {
      loadingWatcher: null
    }
  },
  created () {
    this.loadingWatcher = this.$watch('isLoading', (newValue) => {
      newValue ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden')
    });
    document.body.classList.add('overflow-hidden')
  },
  beforeDestroy () {
    document.body.classList.remove('overflow-hidden')
    this.loadingWatcher()
  }
}
</script>

<style lang="scss" scoped>
.loading {
  align-items: center;
  background: rgba($clr-white, .8);
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  max-height: 100%;
  position: absolute;
  width: 100%;
  z-index: 100;
  &__txt {
    color: $clr-font-primary;
    font-weight: 700;
    align-items: center;
    display: flex;
    flex-direction: column;
    &--sm {
      font-size: .875em;
    }
  }
  &__letters {
    font-size: 1.25em;
    letter-spacing: 2px;
    display: inline-block;
  }
  &__ellip {
    span {
      color: $clr-primary;
      font-size: 5em;
      display: inline-block;
      margin-bottom:- 5px;
      animation: bounce-out-down 2400ms $ease infinite;
      &:nth-of-type(2) {
        animation-delay: 350ms;
      }
      &:nth-of-type(3) {
        animation-delay: 200ms;
      }
    }
  }
}
.fade {
  &-enter {
    &-active {
      transition: background-color 11000ms $easeOutExpo, color 400ms ease 300ms;
    }
  }
  &-leave {
    transition: background-color 9000ms $easeInExpo, color 300ms ease;
  }
}

@keyframes bounce-out-down {
  0% { 
    transform: translateY(5px); 
  }
  20% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

</style>