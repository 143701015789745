<template>
  <div class="panel" :class="panelClasses" :style="cssProps">
    <div
      class="panel__title" :class="{'panel__title-border': titleBorder}"
      :style="{ gridTemplateColumns: '1.5em repeat(' + titleColumns + ', 1fr)' }"
      @click="togglePanel"
    >
      <div>
        <button class="panel__btn">
          <IconChevron/>
        </button>
      </div>
      <slot name="title"></slot>
    </div>
    <transition
      name="panel__drop"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
    >
      <div class="panel__drop" v-if="isOpen" ref="drop">
        <div class="panel__content" :class="{'panel__content-border': titleBorder}" ref="content">
          <slot name="content" ref="slotContent"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IconChevron from './images/icon-chevron';

export default {
  name: 'CollapsiblePanel',
  props: {
    titleColumns: {
      type: Number,
      default: 1
    },
    titleBorder: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: '#FFF'
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  components: {
    IconChevron
  },
  computed: {
    panelClasses() {
      return this.isOpen ? 'is-expanded' : 'is-collapsed';
    },
    cssProps() {
      return {
        '--bg-color': this.backgroundColor,
      }
    }
  },
  methods: {
    togglePanel() {
      this.isOpen = !this.isOpen;
    },
    beforeEnter(el) {
      requestAnimationFrame(() => {
        el.style.maxHeight = '1000px';
        el.style.opacity = '1';
      });
    },
    beforeLeave(el) {
      requestAnimationFrame(() => {
        el.style.maxHeight = '0px';
        el.style.opacity = '0';
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$bezier: cubic-bezier(0.470, 0.000, 0.745, 0.715);
.panel {
  background-color: var(--bg-color);
  $p: &;
  &:last-of-type {
    border-bottom: 1px solid $stone-5;
    margin-bottom: 0.5em;
  }
  &__title {
    font-size: 0.875em;
    align-items: center;
    cursor: default;
    display: grid;
    padding: .5em 0;
    transition: background-color 300ms ease;
    &:hover {
      background-color: rgba(238, 238, 238, .25);
    }
    strong {
      color: #a9a9a9;
    }
  }
  &__title-border {
    border-top: 1px solid $stone-5;
  }
  &__btn {
    color: #205D5F;
    font-size: 2em;
    background: transparent;
    align-items: center;
    border: 0;
    display: flex;
    justify-content: center;
    padding: 5px;
    &:focus {
      outline-color: #17a2b8;
    }
    svg {
      height: auto;
      width: .357em;
      transform: rotate(180deg);
      transition: transform 200ms $bezier 75ms;
      @at-root .is-expanded & {
        transform: rotate(0deg);
      }
    }
  }
  &__content {
    padding: 1em 1.5em;
  }
  &__content-border {
    border-top: 1px solid $stone-5;
  }
  &__drop {
    max-height: 0;
    opacity: 0;
    &-enter-active {
      transition: max-height 250ms $bezier, opacity 300ms $bezier 100ms;
    }
    &-leave-active {
      transition: max-height 250ms $bezier 100ms, opacity 300ms $bezier;
    }
  }
}
</style>
