<template>
  <Modal @close="$emit('closeTrackingModal')" :fullWidth="true" :showOverflow="false">
    <template v-slot:title>
      <h5>Registered Agent History</h5>
    </template>
    <template v-slot:body>
        <p>Showing Registered Agent History records for <strong>{{modalData.businessEntityName}} - {{ modalData.jurisdiction.jurisdiction_code }}</strong></p>
        <div>
          <table class="table">
            <thead>
              <th>Classification</th>
              <th>RA Company/Name</th>
              <th>Contact</th>
              <th>Address</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Change Made By</th>
              <th>RA Started At</th>
              <th>RA Ended At</th>
              <th>History Created On</th>
              <th>History Updated On</th>
            </thead>
            <tbody>
            <template v-if="modalData.trackingRecords.length > 0">
              <tr v-for="(record, index) in modalData.trackingRecords" :key="index">
                <td>{{ makeSentenceCase(record.agent_identifier) }}</td>
                <td> {{ getContactAttr(record.contact, 'company_name') }}</td>
                <td>{{ getContactAttr(record.contact, 'computed_name') }}</td>
                <td class="text-nowrap" v-html="getContactAttr(record.contact, 'full_address')"></td>
                <td class="text-nowrap">{{ getContactAttr(record.contact, 'phone') }}</td>
                <td v-html="addSuggestedBreakToEmail(getContactAttr(record.contact, 'email'))"></td>
                <td v-html="addSuggestedBreakToEmail(record.changed_by)"></td>
                <td>{{ record.start_datetime | moment("MM/DD/YY") }}</td>
                <td>{{ record.end_datetime | moment("MM/DD/YY") }}</td>
                <td>{{ record.created_datetime | moment("MM/DD/YY HH:mm")}}</td>
                <td>{{ record.updated_datetime | moment("MM/DD/YY HH:mm")}}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="11">No History Records Found</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
    </template>
  </Modal>
</template>

<script>
import { addSuggestedBreakToEmail, makeSentenceCase } from '@/assets/utils/text-utils';
import Modal from '@/components/common/Modal';
import { computeFullNameOfPersonMixin } from '@/components/mixins';

export default {
  name: 'RaTrackingModal',
  props: ['modalData'],
  mixins: [computeFullNameOfPersonMixin],
  components: {
    Modal
  },
  computed: {

  },
  data () {
    return {}
  },
  methods: {
    addSuggestedBreakToEmail,
    makeSentenceCase,
    getContactAttr(contact, attr) {
      if (!contact) {
        return ''
      }
      if (attr === 'computed_name') {
        return this.computeFullNameOfPerson(contact)
      }
      else if (attr === 'full_address') {
        return this.getFullAddress(contact);
      }
      return contact[attr]
    },
    getFullAddress(contact) {
      let address = ''
      address = contact['address_1'] + '</br>'
      if (contact['address_2']) {
        address += contact['address_2'] + '</br>'
      }
      address += contact['city'] + ', '
      const addrParts = ['state', 'zip', 'country']
      addrParts.forEach(addrPart => {
        if (contact[addrPart]) {
          address += contact[addrPart] + ' '
        }
      })
      return address
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns:
    minmax(max-content, max-content)
    minmax(auto, max-content)
    minmax(min-content, max-content)
    minmax(min-content, max-content)
    minmax(min-content, max-content)
    minmax(auto, max-content)
    minmax(auto, max-content)
    minmax(auto, max-content)
    minmax(6em, auto)
    minmax(6em, auto)
    minmax(6em, auto);
  overflow-x: auto;
}
thead,
tbody,
tr {
  display: contents;
}
</style>
